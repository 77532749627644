<template>
  <div>
    <BCard>
      <b-row class="justify-content-between mb-[24px]">
        <b-col
          cols="auto"
          class="flex gap-2 items-center"
        >
          <b-button
            variant="primary"
            size="sm"
            class="rounded-lg my-auto"
            @click="$router.push('/grup-contact')"
          >
            <b-icon-chevron-left />
          </b-button>
          <span><h4>
            <strong class="text-black text-2xl">Daftar Kontak</strong>
          </h4></span>
          <div
            class="bg-[#F4F4F4] rounded-lg gap-2 p-[3px] px-[7px] flex items-center"
          ><img
            v-if="dataGroup.created_from === 'komship'"
            src="https://storage.googleapis.com/komerce/assets/LP-Komerce/komship.svg"
            alt="icon komship"
          ><span>{{ dataGroup.name }}</span></div>
        </b-col>
      </b-row>
      <b-row class="justify-content-between mb-[24px]">
        <BCol
          class="mb-[5px]"
          md="3"
        >
          <BInputGroup class="input-group-merge">
            <BInputGroupPrepend is-text>
              <feather-icon icon="SearchIcon" />
            </BInputGroupPrepend>
            <BFormInput
              v-model="search"
              size="md"
              placeholder="Cari berdasarkan Nama/No HP"
              style="padding: 8px 1rem 8px 0px"
              @input="searchData"
            />
          </BInputGroup>
        </BCol>
        <BCol
          v-if="dataGroup.created_from === 'manual'"
          class="mb-[5px]  text-center"
          md="auto"
        >
          <b-dropdown
            id="dropdown-right"
            text="+ Tambah"
            variant="primary"
            class="w-100"
          >
            <b-dropdown-item @click="addKontak">
              <div class="flex items-center gap-[14px] item-list">
                <span>+</span>
                <span>Input Kontak Baru</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="importFromExcel">
              <div class="flex items-center gap-[14px] item-list">
                <span>+</span>
                <span>Input dari Excel</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </BCol>
      </b-row>
      <div v-show="progress.success !== 0">
        <div class="mb-[6px] text-[#222222] text-[14px]">
          Sedang import File
        </div>
        <div class="flex justify-between w-full items-center">
          <div class="w-[97%]">
            <b-progress
              variant="success"
              :max="progress.total"
            >
              <b-progress-bar
                :value="progress.success"
              />
            </b-progress>
          </div>
          <div class="text-sm text-[#222222]">
            {{ `${Math.round(progress.percentage)}%` }}
          </div>
        </div>

      </div>
      <BOverlay
        :show="isLoading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <div
          id="infinite-list"
          style="height: calc(100vh - 310px); overflow-y: scroll;"
        >
          <BTable
            id="table-pelanggan"
            :items="items"
            :fields="fields"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
            :selectable="dataGroup.created_from !== 'manual'"
            class="mt-1"
            @row-selected="handleToDetail"
          >

            <template #cell(action)="data">
              <div class="d-flex align-items-center gap-[15px]">
                <b-button
                  v-b-toggle.sidebar-backdrop
                  size="sm"
                  class="p-0"
                  variant="light"
                  style="background-color: transparent; border: none"
                  @click="editContact(data.item)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                    class="max-w-[25px]"
                  >
                </b-button>
                <b-button
                  size="sm"
                  class="p-0"
                  variant="light"
                  style="background-color: transparent; border: none"
                  @click="deleteKontak(data.item)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                    class="max-w-[25px]"
                  >
                </b-button>
              </div>
            </template>

          </BTable>
        </div>
      </BOverlay>
      <BRow class="mt-1">
        <BCol
          cols="12"
          class="d-flex justify-content-between"
        >
          <div class="d-flex justify-content-center align-items-center gap-[16px]">
            <strong class="text-[#333]"> Total Daftar Kontak </strong>
            <strong class="text-[#F95031]">{{ items.length || 0 }}</strong>
          </div>
        </BCol>
      </BRow>
    </BCard>
    <ModalDeleteKontak
      :id="dataDeleteKontak"
      :get-all-contact="getAllContact"
    />
    <ModalEditContact
      :data="dataEditContact"
      :get-all-contact="getAllContact"
    />
    <ModalImportFromExcel
      @handleActionImport="onHandleActionImport"
    />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { apiImportContactFromExcel } from './Broadcast/service/api.groupContact.service'

import ModalDeleteKontak from './modal/ModalDeleteContact.vue'
import ModalEditContact from './modal/ModalEditContact.vue'
import ModalImportFromExcel from './modal/ModalImportFromExcel.vue'

export default {
  components: {
    ModalDeleteKontak,
    ModalEditContact,
    ModalImportFromExcel,
  },
  data() {
    return {
      search: '',
      loading: false,
      isLoading: false,
      dataDeleteKontak: 0,
      dataEditContact: {},
      dataGroup: JSON.parse(this.$route.params.data),
      fields: [
        {
          key: 'name',
          label: 'Nama',
          thClass: 'font-bold text-black',
          class: 'align-middle text-black',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'No. HP',
          thClass: 'font-bold',
          class: 'align-middle text-black',
        },
        {
          key: 'address',
          label: 'Lokasi',
          thClass: 'font-bold',
          class: 'align-middle text-black',
        },
        {
          key: 'action',
          label: 'Aksi',
          thClass: 'font-bold',
          class: 'align-middle text-black',
        },
      ],
      items: [],
      cursor: 0,
      limit: 30,
      lastData: false,
      progress: {
        total: 0,
        success: 0,
        percentage: 0,
      },
      progressInterval: null,
    }
  },
  mounted() {
    if (!this.dataGroup) {
      this.toGroupContact()
    }
    this.getAllContact()
    this.beforeDestroy()
    const listElm = document.getElementById('infinite-list')
    listElm.addEventListener('scroll', e => {
      if (
        Math.ceil(listElm.scrollTop + listElm.clientHeight)
          >= listElm.scrollHeight
        && !this.loading && !this.lastData
      ) {
        this.nextData()
      }
    })
  },
  beforeDestroy() {
    this.stopProgressTracking()
  },
  methods: {
    searchData: _.debounce(function search() {
      this.getAllContact()
    }, 1000),
    async getAllContact() {
      this.isLoading = true
      const params = {
        search: this.search,
        limit: this.limit,
        cursor: 0,
      }
      const idParams = this.dataGroup.id
      await this.$http_new.get(`/komchat-api/api/v1/contact/${idParams}/list`, { params })
        .then(res => {
          const { data, meta } = res.data
          this.cursor = meta.pagination.next_cursor || 0
          if (data.length !== 0) {
            const { contacts } = data
            this.items = contacts
            this.lastData = contacts.length < this.limit || this.cursor === 0
          }
          this.updateProgress(data.import_excel_progress)
          this.isLoading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
    async nextData() {
      if (!this.lastData) {
        this.isLoading = true
        const params = {
          search: this.search,
          limit: this.limit,
          cursor: this.cursor,
        }
        const idParams = this.dataGroup.id
        await this.$http_new.get(`/komchat-api/api/v1/contact/${idParams}/list`, { params })
          .then(res => {
            const { data, meta } = res.data
            const { contacts } = data
            this.items.push(...contacts)
            this.isLoading = false
            this.cursor = meta.pagination.next_cursor || 0
            this.lastData = contacts.length < this.limit || this.cursor === 0
          })
          .catch(err => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Gagal',
                  icon: 'AlertCircleIcon',
                  text: err.message,
                  variant: 'danger',
                },
              },
              2000,
            )
            this.isLoading = false
          })
      }
    },
    handleToDetail(data) {
      const id = data[0].customer_id
      router.push({ name: 'detail-customer-contact', params: { customer_id: id, data: this.dataGroup } })
    },
    addKontak() {
      const { id } = this.dataGroup
      const datas = this.items

      localStorage.setItem('contactData', JSON.stringify(datas))
      this.$router.push({ name: 'create-contact', params: { id, data: this.dataGroup } })
    },
    toGroupContact() {
      this.$router.push('/grup-contact')
    },
    beforeDestroy() {
      localStorage.removeItem('contactData')
    },
    deleteKontak(data) {
      this.dataDeleteKontak = data.id
      this.$bvModal.show('modal-delete-kontak')
    },
    editContact(data) {
      this.dataEditContact = data
    },
    importFromExcel() {
      this.$bvModal.show('modal-import-from-excel')
    },
    async onHandleActionImport(file) {
      try {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('group_contact_id', this.dataGroup.id)
        const { data } = await apiImportContactFromExcel({ formData })
        if (data.meta.code === 200) {
          this.isLoading = true
          this.progress.success = 1
          this.progress.total = 100
          this.progress.percentage = 0
          this.startProgressTracking()
        }
      } catch (err) {
        const { message } = err.response.data.meta
        this.$toast_error({ message: `Gagal import file : ${message}` })
      }
    },

    updateProgress(data) {
      if (data !== undefined) {
        this.startProgressTracking()
        this.isLoading = true
        if (data.status !== 'READY') {
          this.progress.success = data.contact_success
          this.progress.total = data.contact_total
          this.progress.percentage = data.percentage
        }
      } else {
        this.stopProgressTracking()
        this.progress.success = 0
        this.progress.total = 0
        this.progress.percentage = 0
      }
    },
    startProgressTracking() {
      if (!this.progressInterval) {
        this.progressInterval = setInterval(this.getAllContact, 7000)
      }
    },
    stopProgressTracking() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval)
        this.progressInterval = null
      }
    },
  },
}
</script>

  <style scoped>
  ul li {
      width: max-content !important;
  }
  </style>
